






















































import Vue from 'vue';
import QuizGroupApi from '@/apis/QuizGroupApi';
import { QuizGroupModel } from '@/apis/models/QuizGroupModel';
import QuizEventApi from '@/apis/QuizEventApi';
import { Role } from '@/apis/models/RoleModel';
import { WorkspaceFolderModel } from '@/apis/models/WorkspaceObjectModel';
import { WorkspaceRootFolderModel } from '@/apis/models/WorkspaceModel';
import { Location } from 'vue-router';

interface ButtonModel {
  icon: string;
  label: string;
  disabled: boolean;
  disabledShare: boolean;
  action: null | Function;
}

interface SubpageModel {
  icon: string;
  label: string;
  location: Location;
}

interface ContextModel {
  title: string;
  buttons: ButtonModel[];
  subpages: SubpageModel[];
}

export default Vue.extend({
  name: 'SideNav',
  data() {
    return {
      contents: {

      },
    };
  },
  computed: {
    accountRoles(): Role[] {
      if (this.$store.state.profile) { return this.$store.state.profile.roles; }

      return [];
    },
    currentFolder(): WorkspaceFolderModel | null {
      return this.$store.state.currentFolder;
    },
    quizFolders(): WorkspaceRootFolderModel {
      if (this.$store.state.currentWorkspace) { return this.$store.state.currentWorkspace.quizFolders; }

      // return empty value if workspace is null
      return {
        local: '',
        share: '',
        trash: '',
      };
    },
    memberFolders(): WorkspaceRootFolderModel {
      if (this.$store.state.currentWorkspace) { return this.$store.state.currentWorkspace.memberFolders; }

      // return empty value if workspace is null
      return {
        local: '',
        share: '',
        trash: '',
      };
    },
    eventFolders(): WorkspaceRootFolderModel {
      if (this.$store.state.currentWorkspace) { return this.$store.state.currentWorkspace.eventFolders; }

      // return empty value if workspace is null
      return {
        local: '',
        share: '',
        trash: '',
      };
    },
    context(): ContextModel {
      if (this.$route.path.includes('quizgroups')) {
        return {
          title: this.$t('我的題庫').toString(),
          buttons: [
            {
              icon: '<i class="fa fa-plus" />',
              label: this.$t('創建新題組').toString(),
              disabled: !this.accountRoles.includes(Role.QUIZ_EDITOR) && !this.accountRoles.includes(Role.ADMIN),
              disabledShare: this.$route.path.includes('share'),
              action: this.createNewQuizGroup,
            },
            {
              icon: '<i class="fas fa-folder" />',
              label: this.$t('建立新資料夾').toString(),
              disabled: !this.accountRoles.includes(Role.QUIZ_EDITOR) && !this.accountRoles.includes(Role.ADMIN),
              disabledShare: this.$route.path.includes('share'),
              action: this.creatNewFolder,
            },
          ],
          subpages: [
            {
              icon: '<i class="fas fa-folder" />',
              label: this.$t('我的題庫').toString(),
              location: {
                name: 'QuizGroups',
                params: {
                  folderId: this.quizFolders.local,
                },
              },
            },
            {
              icon: '<i class="fas fa-user-friends" />',
              label: this.$t('與我共用').toString(),
              location: {
                name: 'QuizGroupSoftLinks',
                params: {
                  folderId: this.quizFolders.share,
                },
              },
            },
            // {
            //   icon: '<i class="fas fa-trash-alt" />',
            //   label: '垃圾桶',
            //   location: {
            //     name: 'QuizGroupTrash',
            //     params: {
            //       folderId: this.quizFolders.trash,
            //     },
            //   },
            // },
          ],
        };
      }

      if (this.$route.path.includes('membergroups')) {
        return {
          title: this.$t('我的班級').toString(),
          buttons: [
            {
              icon: '<i class="fa fa-plus" />',
              label: this.$t('創建新班級').toString(),
              disabled: !this.accountRoles.includes(Role.MEMBER_EDITOR) && !this.accountRoles.includes(Role.ADMIN),
              disabledShare: this.$route.path.includes('share'),
              action: this.createNewMemberGroup,
            },
            {
              icon: '<i class="fas fa-folder" />',
              label: this.$t('建立新資料夾').toString(),
              disabled: !this.accountRoles.includes(Role.MEMBER_EDITOR) && !this.accountRoles.includes(Role.ADMIN),
              disabledShare: this.$route.path.includes('share'),
              action: this.creatNewFolder,
            },
          ],
          subpages: [
            {
              icon: '<i class="fas fa-folder" />',
              label: this.$t('我的班級').toString(),
              location: {
                name: 'MemberGroups',
                params: {
                  folderId: this.memberFolders.local,
                },
              },
            },
            {
              icon: '<i class="fas fa-user-friends" />',
              label: this.$t('與我共用').toString(),
              location: {
                name: 'MemberGroupSoftLinks',
                params: {
                  folderId: this.memberFolders.share,
                },
              },
            },
            // {
            //   icon: '<i class="fas fa-trash-alt" />',
            //   label: '垃圾桶',
            //   location: {
            //     name: 'MemberGroupTrash',
            //     params: {
            //       folderId: this.memberFolders.trash,
            //     },
            //   },
            // },
          ],
        };
      }

      if (this.$route.path.includes('quizevents')) {
        return {
          title: this.$t('我的測驗').toString(),
          buttons: [
            {
              icon: '<i class="fa fa-plus" />',
              label: this.$t('創建新測驗').toString(),
              disabled: !this.accountRoles.includes(Role.EVENT_EDITOR) && !this.accountRoles.includes(Role.ADMIN),
              disabledShare: this.$route.path.includes('share'),
              action: this.createNewQuizEvent,
            },
            {
              icon: '<i class="fas fa-folder" />',
              label: this.$t('建立新資料夾').toString(),
              disabled: !this.accountRoles.includes(Role.EVENT_EDITOR) && !this.accountRoles.includes(Role.ADMIN),
              disabledShare: this.$route.path.includes('share'),
              action: this.creatNewFolder,
            },
          ],
          subpages: [
            {
              icon: '<i class="fas fa-folder" />',
              label: this.$t('我的測驗').toString(),
              location: {
                name: 'QuizEvents',
                params: {
                  folderId: this.eventFolders.local,
                },
              },
            },
            {
              icon: '<i class="fas fa-user-friends" />',
              label: this.$t('與我共用').toString(),
              location: {
                name: 'QuizEventSoftLinks',
                params: {
                  folderId: this.eventFolders.share,
                },
              },
            },
            // {
            //   icon: '<i class="fas fa-trash-alt" />',
            //   label: '垃圾桶',
            //   location: {
            //     name: 'QuizEventTrash',
            //     params: {
            //       folderId: this.eventFolders.trash,
            //     },
            //   },
            // },
          ],
        };
      }
      return {
        title: 'Workspace',
        buttons: [],
        subpages: [],
      };
    },
  },
  methods: {
    creatNewFolder() {
      console.log('creatNewFolder');
      this.$modal.show('create-folder-modal');
    },
    createNewQuizGroup() {
      const { currentFolder } = this.$store.state;
      QuizGroupApi.create(currentFolder.uuid).then((group: QuizGroupModel) => {
        this.$store.commit('changeQuizGroup', group);
        this.$router.push('/manager/quizgroup-maker');
      });
    },
    createNewMemberGroup() {
      if (this.$route.name === 'MemberGroups') {
        // console.log('已在頁面');
      } else {
        this.$router.push('/manager/workspace/membergroups');
      }
      this.$modal.show('create-memberGroup-modal');
    },
    createNewQuizEvent() {
      const { currentFolder } = this.$store.state;
      QuizEventApi.create(currentFolder.uuid).then((group) => {
        this.$store.commit('changeQuizEvent', group);
        this.$router.push('/manager/quizevent-maker');
      });
    },
  },
});
