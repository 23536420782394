





























import Vue from 'vue';
import MemberGroupApi from '@/apis/MemberGroupApi';
import { WorkspaceFolderModel, WorkspaceObjectModel } from '@/apis/models/WorkspaceObjectModel';
import WorkspaceObjectApi from '@/apis/WorkspaceObjectApi';

const MODAL_WIDTH = 656;
export default Vue.extend({
  data() {
    return {
      modalWidth: MODAL_WIDTH,
      remind: '',
      memberGroupName: '',
      workspaceObject: null as WorkspaceObjectModel | null,
    };
  },
  computed: {
    currentFolder(): WorkspaceFolderModel {
      return this.$store.state.currentFolder;
    },
  },
  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    beforeOpen(event: any) {
      this.remind = '';
      if (event.params.workspaceObject) {
        this.workspaceObject = event.params.workspaceObject as WorkspaceObjectModel;
        this.memberGroupName = this.workspaceObject.metadata.name;
      }
    },
    handleCancel() {
      console.log('取消複製班級');
      this.$modal.hide('copy-memberGroup-modal');
    },
    async handleSubmit() {
      const regex = /^[^\s_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/g;
      // console.log(this.memberGroupName.search(regex));
      if (this.memberGroupName.search(regex) === 0) {
        this.remind = '';
        if (this.workspaceObject && this.workspaceObject.metadata.parentId) {
          const copiedObject = await WorkspaceObjectApi.copyMemberGroup(this.workspaceObject.uuid, this.workspaceObject.metadata.parentId, this.memberGroupName);
          this.$modal.hide('copy-memberGroup-modal');
          if (this.currentFolder) { this.currentFolder.children.push(copiedObject); }
        }
      } else {
        this.remind = this.$t('只允許中文、英文及數字，且至少兩個字。不允許任何空白、特殊符號(~!@#$%^&*()_/)。').toString();
      }
    },
  },
});
