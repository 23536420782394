











































































































import Vue from 'vue';
import SoftLinkApi from '@/apis/SoftLinkApi';
import { WorkspaceSoftLinkModel, Permission, UpdateSoftLinkPayload } from '@/apis/models/WorkspaceSoftLinkModel';

const MODAL_WIDTH = 640;
export default Vue.extend({
  data() {
    return {
      modalWidth: MODAL_WIDTH,
      softLinks: [] as WorkspaceSoftLinkModel[],
      userInput: {
        search: '',
        searchState: null as null | boolean,
        permission: Permission.DETAIL_VIEWER,
      },
      permissionOptions: Object.keys(Permission),
      sourceObjectId: '',
    };
  },
  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    beforeOpen(event: any) {
      // clean softLinks and input entries
      this.softLinks = [] as WorkspaceSoftLinkModel[];
      this.userInput = {
        search: '',
        searchState: null as null | boolean,
        permission: Permission.DETAIL_VIEWER,
      };

      this.sourceObjectId = event.params.sourceObjectId;

      this.loadSoftLinks();
    },
    async loadSoftLinks() {
      this.softLinks = await SoftLinkApi.list(this.sourceObjectId);
    },
    async createSoftLink() {
      try {
        const softLink = await SoftLinkApi.create(this.sourceObjectId, this.userInput.search, this.userInput.permission);
        this.softLinks.push(softLink);
        this.userInput.searchState = true;
        this.userInput.search = '';
      } catch (error) {
        if (error.response && error.response.status) {
          if (error.response.status === 404) {
            this.userInput.searchState = false;
          }
        }
      }
    },
    async updateSoftLink(softLink: WorkspaceSoftLinkModel, permission: Permission) {
      const payload = new UpdateSoftLinkPayload(permission);

      const updatedSoftLink = await SoftLinkApi.update(softLink.uuid, payload);
      softLink.permission = updatedSoftLink.permission;
    },
    async deleteSoftLink(index: number) {
      await SoftLinkApi.destroy(this.softLinks[index].uuid);
      this.softLinks.splice(index, 1);
    },
    handleFinish() {
      this.$modal.hide('manage-share-mapping-modal');
    },
    translatePermission(permission: Permission): string {
      switch (permission) {
        case Permission.EDITOR:
          return this.$t('編輯者').toString();
        case Permission.DETAIL_VIEWER:
          return this.$t('檢視者').toString();
        case Permission.GENERAL_VIEWER:
          return this.$t('使用者').toString();
        default:
          return this.$t('無權限').toString();
      }
    },
  },
});
