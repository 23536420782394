import api from '@/apis/BaseApi';
import { WorkspaceObjectModel } from './models/WorkspaceObjectModel';
import { Permission, UpdateSoftLinkPayload, WorkspaceSoftLinkModel } from './models/WorkspaceSoftLinkModel';

/**
 * SoftLinkApi
 *
 * Modularized Soft Link API,
 * current design is embedding it in workspace object API,
 *
 */
export default class SoftLinkApi {
  baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  static async list(sourceObjectId: string): Promise<WorkspaceSoftLinkModel[]> {
    const result = await api.get(`workspace/soft-links?source-object-id=${sourceObjectId}`);
    return result.data.softLinks;
  }

  static async create(sourceObjectId: string, receiverUsername: string, permission: Permission): Promise<WorkspaceSoftLinkModel> {
    const body = {
      sourceObjectId,
      receiverUsername,
      permission,
    };
    const result = await api.post('workspace/soft-links', body);
    return result.data.softLink;
  }

  static async update(softLinkId: string, updatePayload: UpdateSoftLinkPayload): Promise<WorkspaceSoftLinkModel> {
    const body = {
      ...updatePayload,
    };
    const result = await api.put(`workspace/soft-links/${softLinkId}`, body);
    return result.data.softLink;
  }

  static async destroy(softLinkId: string): Promise<void> {
    await api.delete(`workspace/soft-links/${softLinkId}`);
  }

  /**
   * Retrieve a workspace object by soft link id
   *
   * currently no use
   *
   * @param softLinkId id of the soft link
   * @returns WorkspaceObject where the given WorkpsaceSoftLink.sourceObject points
   */
  static async retrieveSource(softLinkId: string): Promise<WorkspaceObjectModel> {
    return api.get(`workspace/soft-links/${softLinkId}`);
  }
}
