





























import Vue from 'vue';
import MemberGroupApi from '@/apis/MemberGroupApi';
import { WorkspaceFolderModel } from '@/apis/models/WorkspaceObjectModel';

const MODAL_WIDTH = 656;
export default Vue.extend({
  data() {
    return {
      modalWidth: MODAL_WIDTH,
      memberGroupName: '',
      remind: '',
    };
  },
  computed: {
    currentFolder(): WorkspaceFolderModel {
      return this.$store.state.currentFolder;
    },
  },
  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
  },
  methods: {
    beforeOpen() {
      this.memberGroupName = '';
      this.remind = '';
    },
    cencelCreateMemberGroup() {
      console.log('取消創建班級');
      this.$modal.hide('create-memberGroup-modal');
    },
    createMemberGroup() {
      const regex = /^[^\s_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/g;
      console.log(this.memberGroupName.search(regex));
      if (this.memberGroupName.search(regex) === 0) {
        this.remind = '';
        MemberGroupApi.create(this.currentFolder.uuid, this.memberGroupName).then((newMemberGroupObject) => {
          this.$modal.hide('create-memberGroup-modal');
          this.currentFolder.children.push(newMemberGroupObject);
          console.log(newMemberGroupObject);
        });
      } else {
        this.remind = this.$t('只允許中文、英文及數字，且至少兩個字。不允許任何空白、特殊符號(~!@#$%^&*()_/)。').toString();
      }
    },
  },
});
