





























import Vue from 'vue';
import FolderApi from '@/apis/FolderApi';
import { WorkspaceFolderModel } from '@/apis/models/WorkspaceObjectModel';

const MODAL_WIDTH = 656;
export default Vue.extend({
  data() {
    return {
      modalWidth: MODAL_WIDTH,
      folderName: '未命名資料夾',
      remind: '',
    };
  },
  computed: {
    currentFolderId(): string {
      return this.$store.state.currentFolder.uuid;
    },
    currentFolder(): WorkspaceFolderModel {
      return this.$store.state.currentFolder;
    },
  },
  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
  },
  methods: {
    beforeOpen() {
      this.folderName = this.$t('未命名資料夾').toString();
      this.remind = '';
    },
    cencelCreateFolder() {
      console.log('取消建立資料夾');
      this.$modal.hide('create-folder-modal');
    },
    createFolder() {
      FolderApi.create(this.folderName, this.currentFolderId).then((newFolder) => {
        this.currentFolder.children.push(newFolder);
        this.$modal.hide('create-folder-modal');
      });
    },
  },
});
