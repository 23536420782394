















import Vue from 'vue';
import SideNav from '@/components/workspace/SideNav.vue';
import CreateMemberGroupModal from '@/components/workspace/CreateMemberGroupModal.vue';
import CopyMemberGroupModal from '@/components/workspace/CopyMemberGroupModal.vue';
import CreateFolderGroupModal from '@/components/workspace/CreateFolderModal.vue';
import ManageShareMappingModal from '@/components/workspace/ManageShareMappingModal.vue';
import Auth from '@/libs/auth';
import { WorkspaceDataModel } from '@/apis/models/WorkspaceModel';

export default Vue.extend({
  components: {
    SideNav,
    CreateMemberGroupModal,
    CopyMemberGroupModal,
    CreateFolderGroupModal,
    ManageShareMappingModal,
  },
  data() {
    return {
    };
  },
  computed: {
    currentWorkspace(): WorkspaceDataModel {
      return this.$store.state.currentWorkspace;
    },
  },
  watch: {
  },
  async mounted() {
    Auth.loginCheck();
  },
  methods: {
  },
});
